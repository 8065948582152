<template>
  <v-container>
    <v-textarea placeholder="C: server.com 5000 user password" v-model="form_data"></v-textarea>
    <v-btn elevation="2" @click="sendData" :disabled="!form_data">Generate</v-btn>
    <v-btn elevation="2" @click="downloadFile" :disabled="!form_data">
      <v-icon>mdi-file-outline</v-icon>
      File
    </v-btn>
    <v-row justify="center">
      <v-dialog v-model="dialog" width="600px">
        <v-card>
          <v-card-title>
            <span class="headline">Insert into oscam.server file</span>
          </v-card-title>
          <v-card-text>
            <div class="text-no-wrap" v-html="response">
              {{ response }}
            </div>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" text @click="dialog = false">
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <div class="bottom">
      <v-alert type="error" v-model="alert_error" dismissible border="right" transition="scale-transition" :value="alert_error">
        {{ error_message }}
        <ul>
          <li v-for="detail in error_details" :key="detail">
            {{ detail }}
          </li>
        </ul>
      </v-alert>
    </div>
    <Loading v-model="loading"></Loading>
  </v-container>
</template>

<script>
import axios from 'axios';
import Loading from "@/components/Loading";

let base_url = process.env.VUE_APP_BACKEND_URL;
console.log(base_url);

export default {
  name: "FormTextInput",
  components: {Loading},
  data() {
    return {
      loading: false,
      dialog: false,
      form_data: null,
      alert_error: false,
      alert_success: false,
      response: null,
      error_message: '',
      error_details: '',
    }
  },
  methods: {
    openLoading: function () {
      this.loading = true;
    },
    downloadFile: function () {
      this.openLoading();
      axios.post(base_url + 'api/downloadFile', {
        data: this.form_data
      }).then(response => {
        this.loading = false;
        this.handleFileResponse(response);
      }).catch(response => {
        this.loading = false;
        this.error_message = response.data;
        this.alert_error = !this.alert_error;
        this.timeout();
      })
    },
    sendData: function() {
      this.openLoading();
      axios.post(base_url + 'api/post', {
        data: this.form_data
      }).then(response => {
        this.loading = false;
        this.handleResponse(response);
      }).catch(error => {
        this.loading = false;
        console.log(error.response);
        this.error_message = error.response.data.error;
        this.error_details = error.response.data.details;
        this.alert_error = !this.alert_error;
        this.timeout();
      })
    },
    handleFileResponse(response) {
      const FileDownload = require('js-file-download');
      FileDownload(response.data, 'oscam.serve');
    },
    handleResponse(response) {
      this.loading = false;
      if (response.status === 200) {

        let lines = '';
        let readers = response.data;

        readers.forEach(function (reader) {
          lines += reader.header + '<br>';
          lines += 'label = ' + reader.label + '<br>';
          lines += 'description = ' + reader.description + '<br>';
          lines += 'protocol = ' + reader.protocol + '<br>';
          lines += 'enabled = ' + reader.enabled + '<br>';
          lines += 'device = ' + reader.device + '<br>';
          lines += 'user = ' + reader.user + '<br>';
          lines += 'password = ' + reader.password + '<br>';
          lines += 'inactivitytimeout = ' + reader.inactivitytimeout + '<br>';
          lines += 'cacheex = ' + reader.cacheex + '<br>';
          lines += 'group = ' + reader.group + '<br>';
          lines += 'cccversion = ' + reader.cccversion + '<br><br>';
        });

        this.response = lines;
        this.dialog = true;
      } else {
        this.alert_error.toggle();
        this.timeout();
      }
    },
    timeout() {
      setTimeout(() => {
        this.alert_error=false
      },8000)
    }
  }
}
</script>

<style scoped>
.bottom {
  position:absolute;
  bottom:0;
  right:0;
}
</style>