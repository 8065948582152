<template>
  <div id="app">
    <div style="padding-top: 20px; padding-left: 15%">
      <v-breadcrumbs divider=">" :items="items"></v-breadcrumbs>
      <v-expansion-panels style="width: 70%">
        <v-expansion-panel>
          <v-expansion-panel-header>How to use</v-expansion-panel-header>
          <v-expansion-panel-content>
            <p>Just paste your c-lines line by line</p>
            <h4>example:</h4>
            <div class="text-no-wrap">
              <h5>C: server1.com 5001 user1 password1</h5>
              <h5>C: server2.com 5002 user2 password2</h5>
              <h5>C: server3.com 5003 user3 password3</h5>
            </div>
          </v-expansion-panel-content>

        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>Contact Form - Get in Touch with Us!</v-expansion-panel-header>
          <v-expansion-panel-content>
            <p>Have a question, suggestion, or need assistance? Our contact form makes it easy to reach out to us.
              Simply fill in the required fields, leave your message, and our team will get back to you promptly. We
              value your feedback and are here to provide you with the support you need. Don't hesitate to get in touch
              with us using our convenient contact form.</p>

            <form>
              <v-text-field placeholder="Name" label="Name" v-model="form_data.name"></v-text-field>
              <v-text-field placeholder="Email" label="E-mail" v-model="form_data.email"></v-text-field>
              <v-textarea placeholder="Message" v-model="form_data.message"></v-textarea>
              <v-btn class="me-4" @click="sendEmail">submit</v-btn>
            </form>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
    <div class="bottom">
      <v-alert type="error" v-model="alert_error" dismissible border="right" transition="scale-transition" :value="alert_error">
        Error
      </v-alert>
    </div>
    <div class="bottom">
      <v-alert type="success" v-model="alert_success" dismissible border="right" transition="scale-transition" :value="alert_success">
        Message sent successfully
      </v-alert>
    </div>
  </div>
</template>

<script>
import axios from "axios";
let base_url = process.env.VUE_APP_BACKEND_URL;
export default {
  name: "Help",
  data: () => ({
    items: [
      {
        text: "Home",
        href: "/"
      },
      {
        text: "Help",
        disabled: true,
      }
    ],
    alert_error: false,
    alert_success: false,
    form_data: {
      name: '',
      email: '',
      message: ''
    },
  }),
  methods: {
    timeout() {
      setTimeout(() => {
        this.alert_error=false
      },8000)
    },
    sendEmail() {
      axios.post(base_url + 'api/contact', {
        data: this.form_data
      }).then(() => {
        this.alert_success = !this.alert_success;
        this.timeout();
      }).catch(() => {
        this.alert_error = !this.alert_error;
        this.timeout();
      })
    }
  }
}


</script>