<template>
  <div>
  <v-app>
    <v-app-bar app color="primary" dark>
      <div class="d-flex align-center">
        <v-icon>mdi-file-cog-outline</v-icon>
        Online cline to oscam converter
      </div>
      <v-spacer></v-spacer>
      <div>
      <a href="https://www.buymeacoffee.com/badger85">
        <img alt="Coffee" src="https://img.buymeacoffee.com/button-api/?text=Buy me a coffee&emoji=&slug=badger85&button_colour=FFDD00&font_colour=000000&font_family=Cookie&outline_colour=000000&coffee_colour=ffffff">
      </a>
      </div>
      <v-spacer></v-spacer>
      <v-btn
          to="/help"
          text
      >
        <span class="mr-2">Help</span>
        <v-icon>mdi-help</v-icon>
      </v-btn>
    </v-app-bar>
    <v-main>
      <router-view></router-view>
    </v-main>
    <WelcomeSnackbar></WelcomeSnackbar>
    <v-bottom-navigation hide-on-scroll horizontal>
      <v-btn>{{ version }}</v-btn>
    </v-bottom-navigation>
  </v-app>
  </div>
</template>

<script>

import WelcomeSnackbar from "@/components/WelcomeSnackbar";
export default {
  name: 'App',
  components: {WelcomeSnackbar},
  data: () => ({
    version: 'v' + process.env.VUE_APP_VERSION,
  }),
};
</script>
