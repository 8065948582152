import Vue from 'vue';
import Home from './Home.vue';
import vuetify from './plugins/vuetify';
import VueRouter from 'vue-router';
import VueGtag from 'vue-gtag'
import App from "@/App";
import Help from "@/Help";


Vue.use(VueRouter)

Vue.config.productionTip = false

const routes = [
  { path: '/', component: Home },
  { path: '/help', component: Help}
];

const router = new VueRouter({
  routes: routes
});

Vue.use(VueGtag, {
  config: {
    id: 'G-KVLW5PT9HP',
    params: {
      send_page_view: false
    }
  }
}, router)


new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app');