<template>
  <div id="app">
    <div style="padding-top: 20px; padding-left: 15%">
      <div style="width: 70%">
        <v-breadcrumbs divider=">" :items="items"></v-breadcrumbs>
        <FormTextInput/>
      </div>
    </div>
  </div>
</template>

<script>
import FormTextInput from "@/components/FormTextInput";

export default {
  components: {
    FormTextInput
  },
  name: "Home",
  data: () => ({
    items: [
      {
        text: "Home",
        href: '/'
      }
    ]
  })
}
</script>